import React, { ChangeEvent, useState, useRef } from 'react'
import clx from 'classnames'
import ConverterService from 'src/services/admin-services/admin-converter-service'
import { catchErrorAlert, reactNoti } from 'src/libs/reactNoti'

import './import-file.css'

const converteService = new ConverterService()

type ImportFileProps = {
  fileImportedHandler: (data: string) => void
}

export const ImportFile = ({ fileImportedHandler }: ImportFileProps) => {
  const [isFileLoading, setIsFileLoading] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const onFileAddHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setIsFileLoading(true)
    const { files } = e.target

    const file = files?.[0]

    if (file && file.name.search(/^.*\.(docx|doc|pdf|md)$/i) < 0) {
      reactNoti.error('Неверный тип файла')
      setIsFileLoading(false)
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
      return
    }

    const formData = new FormData()
    if (file) formData.append('file', file)

    converteService
      .toHtml(formData)
      .then(data => {
        if (!data) {
          setIsFileLoading(false)

          reactNoti.show('Не удалось импортировать файл')
          return
        }

        fileImportedHandler(data)
        setIsFileLoading(false)
        if (fileInputRef.current) {
          fileInputRef.current.value = ''
        }
        reactNoti.success('Файл успешно импортирован')
      })
      .catch(err => {
        setIsFileLoading(false)
        if (fileInputRef.current) {
          fileInputRef.current.value = ''
        }
        catchErrorAlert(err)
      })
  }

  return (
    <label className={clx('input-file', { loading: isFileLoading })}>
      <input type="file" name="file" ref={fileInputRef} onChange={onFileAddHandler} />
      <span className="btn btn-lg btn-primary input-file-btn">{isFileLoading ? 'Загрузка...' : 'Выберите файл'}</span>
    </label>
  )
}
