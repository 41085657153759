import React, { Component } from 'react'
import { FormControl, FormLabel } from 'react-bootstrap'
import './review-step-task-form.css'
import { withLastLocation } from 'react-router-last-location/dist/index'
import CKEditor from 'ckeditor4-react'
import { catchErrorAlert } from 'src/libs/reactNoti'

import ReviewStepTaskService from '../../../../../services/admin-services/tasks/review-step-task-service'
import { formatErrors } from '../../helper'
import { ReviewType } from '../../../../../model/review/review-type'
import { ImportFile } from '../../import-file'

class ReviewStepTaskForm extends Component {
  state = {
    task: {},
    error: '',
    validationErrors: {},
  }

  service = new ReviewStepTaskService()

  componentDidMount() {
    this.onUpdate()
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props
    if (prevProps.location !== location) {
      this.setState({
        task: {},
      })
      this.onUpdate()
    }
  }

  onUpdate() {
    const {
      match: {
        params: { taskId = 0 },
      },
    } = this.props
    if (taskId > 0) {
      this.service
        .getById(taskId)
        .then(task => {
          this.setState({
            task,
          })
        })
        .catch(error => catchErrorAlert(error))
    } else {
      this.setState({
        task: {},
      })
    }
  }

  editDescription = ({ editor }) => {
    const newDescription = editor.getData()
    this.setState(oldState => {
      return {
        task: {
          ...oldState.task,
          description: newDescription,
        },
      }
    })
  }

  editTitle = ({ target: { value } }) => {
    this.setState(oldState => {
      return {
        task: {
          ...oldState.task,
          title: value,
        },
      }
    })
  }

  editPoints = ({ target: { value } }) => {
    this.setState(oldState => {
      return {
        task: {
          ...oldState.task,
          points: value,
        },
      }
    })
  }

  changeReviewType = ({ target: { value } }) => {
    this.setState(oldState => {
      return {
        task: {
          ...oldState.task,
          reviewType: value,
        },
      }
    })
  }

  onFileImportHandler = data => this.setState({ task: { description: data } })

  render() {
    const { task, error, validationErrors } = this.state
    const { callback, lastLocation } = this.props
    const { id = 0, title = '', reviewType = ReviewType.GROUP, points = 0, description = '' } = task
    return (
      <>
        <h1 className="page-header">{id === 0 ? 'Создание' : 'Редактирование'} шага записи на ревью</h1>
        <div className="form-group">
          <label htmlFor="title_input">Заголовок задачи</label>
          <FormControl
            id="title_input"
            onChange={this.editTitle}
            type="text"
            value={title}
            style={{ borderColor: validationErrors.title ? '#DC3645' : '' }}
          />
          {validationErrors.title && <FormLabel className="text-danger">{validationErrors.title}</FormLabel>}
        </div>
        <div className="form-group">
          <label htmlFor="point_input">Количество очков за решение задачи</label>
          <FormControl id="point_input" type="number" onChange={this.editPoints} value={points} />
          {validationErrors.points && <FormLabel className="text-danger">{validationErrors.points}</FormLabel>}
        </div>
        <div className="form-group">
          <label htmlFor="review_type_select">Тип ревью</label>
          <FormControl as="select" id="review_type_select" onChange={this.changeReviewType} value={reviewType}>
            <option value={ReviewType.GROUP}>Групповое</option>
            <option value={ReviewType.PERSONAL_MENTOR}>Персональное</option>
          </FormControl>
          {validationErrors.reviewType && <FormLabel className="text-danger">{validationErrors.reviewType}</FormLabel>}
        </div>
        <div className="form-group">
          <b>Описание задания</b>
          <div
            style={{
              border: validationErrors.description ? '1px solid #DC3645' : '',
              borderRadius: '.25rem',
            }}
          >
            {description !== undefined && (
              <CKEditor name="description" data={description} onChange={event => this.editDescription(event)} />
            )}
          </div>
          <ImportFile fileImportedHandler={this.onFileImportHandler} />
          {validationErrors.description && (
            <FormLabel className="text-danger">{validationErrors.description}</FormLabel>
          )}
          {error !== '' && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          <div className="form-group">
            <button
              onClick={() => {
                callback(task)
                  .then(() => {
                    if (lastLocation) {
                      document.location = lastLocation.pathname
                    } else {
                      document.location = '/admin/tasks/review-step-task'
                    }
                  })
                  .catch(err => {
                    const formattedErrors = formatErrors(err.errors)

                    this.setState({
                      error:
                        err.code && err.code === 500
                          ? 'Ошибка сервера. Подробная информация - в консоли'
                          : 'Ошибка валидации',
                      validationErrors: formattedErrors,
                    })
                  })
              }}
              type="button"
              className="btn btn-lg btn-block btn-primary"
            >
              Сохранить
            </button>
          </div>
        </div>
      </>
    )
  }
}

export default withLastLocation(ReviewStepTaskForm)
