import React from 'react'
import { reactNoti } from 'src/libs/reactNoti'
import './finish-review-button.css'
import { confirmAlert } from 'react-confirm-alert'
import { history } from '../../../../../../config'

const FinishReviewButton = ({ finishReview, theoryHasReviewed, allStudentsMarkedTheoryReview, started }) => {
  const confirmFinishReview = () => {
    if (!allStudentsMarkedTheoryReview()) {
      reactNoti.error(`Нельзя завершить ревью, пока не выбран статус прохождения хотя бы для одного студента`)
      return
    }

    confirmAlert({
      title: 'Завершение теоретического ревью',
      message: 'Подтверждаешь завершение ревью? Отменить действие будет невозможно!',
      buttons: [
        {
          label: 'Завершить',
          onClick: () => {
            finishReview()
          },
        },
        {
          label: 'Отмена',
        },
      ],
    })
  }
  return (
    <div className="theory-review-button-container">
      <button type="button" className="btn btn-warning finish-review" onClick={() => history.goBack()}>
        Назад
      </button>
      {(!theoryHasReviewed && started) && (
        <button type="button" className="btn btn-danger finish-review" onClick={() => confirmFinishReview()}>
          Завершить
        </button>
      )}
    </div>
  )
}

export default FinishReviewButton
