import React from 'react'
import { FormControl, Form } from 'react-bootstrap'
import './combo-box-courses.css'
import { reactNoti } from 'src/libs/reactNoti'

const ComboBoxCourses = ({
  availableCourses,
  handleChange,
  onAddCourseInDirection,
  minPrevCourseProgressForNextCourse,
  handleMinCourseProgressChange,
  onSetMinCourseProgress,
}) => {
  const courses = availableCourses.map(course => {
    const { id, name } = course
    return (
      <option key={`course-id-${id}`} value={id}>
        {name}
      </option>
    )
  })

  return (
    <div className="review-questions-container">
      <div className="review-questions-field">
        <FormControl className="review-questions-field-select col p-0" as="select" onChange={handleChange}>
          {courses}
        </FormControl>
        <button
          type="button"
          className="btn btn-info review-questions-button col"
          onClick={() => {
            onAddCourseInDirection()
            reactNoti.success('Курс успешно добавлен в направление')
          }}
        >
          Добавить
        </button>
      </div>
      <div className="review-questions-field">
        <Form className="align-items-center m-0">
          <Form.Group className="col p-0 mr-3 mb-0" controlId="formBasicEmail">
            <Form.Label className="review-questions-label-range">Прогресс для доступа к следующему курсу</Form.Label>
            <div className="review-question-wrapper row m-0">
              <Form.Control
                type="number"
                min={0}
                max={100}
                className="review-questions-input-range col mr-3"
                value={minPrevCourseProgressForNextCourse}
                onChange={handleMinCourseProgressChange}
              />
              <button
                type="button"
                className="btn btn-info col"
                onClick={() => {
                  onSetMinCourseProgress()
                  alert.success('Минимальный прогресс для доступа к следующему курсу успешно изменен')
                }}
              >
                Сохранить
              </button>
            </div>
          </Form.Group>
        </Form>
      </div>
    </div>
  )
}

export default ComboBoxCourses
