import React from 'react'
import { FormControl, FormLabel } from 'react-bootstrap'
import CKEditor from 'ckeditor4-react'
import { ImportFile } from '../../../import-file'

const BasicInfoTab = ({ task, editPoints, editDescription, editTitle, validationErrors }) => {
  const { title, points, description } = task

  return (
    <>
      <div className="form-group">
        <label htmlFor="title_input">Заголовок задачи</label>
        <FormControl
          id="title_input"
          onChange={editTitle}
          type="text"
          value={title}
          style={{ borderColor: validationErrors.title ? '#DC3645' : '' }}
        />
        {validationErrors.title && <FormLabel className="text-danger">{validationErrors.title}</FormLabel>}
      </div>
      <div className="form-group">
        <label htmlFor="point_input">Количество очков за решение задачи</label>
        <FormControl id="point_input" type="number" onChange={editPoints} value={points} />
        {validationErrors.points && <FormLabel className="text-danger">{validationErrors.points}</FormLabel>}
      </div>
      <div className="form-group">
        <b>Описание задания</b>
        <div
          style={{
            border: validationErrors.description ? '1px solid #DC3645' : '',
            borderRadius: '.25rem',
          }}
        >
          <CKEditor
            name="description"
            onChange={({ editor }) => {
              editDescription(editor.getData())
            }}
            data={description}
          />
          <ImportFile fileImportedHandler={editDescription} />
        </div>
        {validationErrors.description && <FormLabel className="text-danger">{validationErrors.description}</FormLabel>}
      </div>
    </>
  )
}

export default BasicInfoTab
